.formContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.productSelectorContainer {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.productSelectionColumn {
  flex: 0 0 auto;
  width: calc(50% - 1rem);
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.selectedProductsColumn {
  flex: 1;
  min-width: calc(50% - 1rem);
  display: flex;
  flex-direction: column;
}

.searchContainer {
  margin-bottom: 1rem;
}

.searchInput {
  width: 100%;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.productCard {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.productCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.productCard.selected {
  border-color: #1976d2;
  background-color: #e3f2fd;
}

.productCard,
.selectedProductCard {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.productImage {
  height: 120px;
  background-size: cover;
  background-position: center;
}

.productInfo {
  padding: 0.5rem;
}

.productName {
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productNumber {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.selectButton {
  width: 100%;
  padding: 0.4rem;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.selectButton:hover {
  background-color: #1565c0;
}

.selectedProductHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.selectedProductTitle {
  font-weight: bold;
  font-size: 1.2rem;
}

.selectedProductContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-height: 400px;
  overflow-y: auto;
}

.selectedProductCard {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.selectedProductInfo {
  width: 100%;
}

.selectedProductTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.removeButton {
  background: none;
  border: none;
  color: #f44336;
  cursor: pointer;
  padding: 0.25rem;
}

.quantityInput {
  width: 100%;
  margin-top: 0.5rem;
}

.loadMoreButton {
  margin-top: 1rem;
  width: 100%;
}

.selectedProductSummary {
  margin-top: 1rem;
  font-weight: bold;
}

.errorMessage {
  color: #f44336;
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .productSelectorContainer {
    flex-direction: column;
  }

  .selectedProductsColumn {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .productGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
