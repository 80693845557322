.card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.title {
  margin-bottom: 24px;
  color: inherit;
}

.subtitle {
  margin-top: 32px;
  margin-bottom: 16px;
  color: inherit;
}

.section {
  margin-bottom: 24px;
}

.labeledField {
  margin-bottom: 16px;
}

.fieldLabel {
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
}

.imageField img {
  max-width: 160px;
  height: auto;
  object-fit: cover !important;
  border-radius: 4px;
}

.configSection {
  background-color: inherit;
  border-radius: 4px;
  padding: 12px;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.chip {
  background-color: #e0e0e0 !important;
  color: #333333;
}

.configLabel {
  font-weight: bold;
  margin-bottom: 8px !important;
}

.chipContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
