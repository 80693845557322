.product_list {
  width: 100%;
}

.search_input {
  margin-bottom: 1rem;
  width: 100%;
  min-width: 320px !important;
}

.desktop_view {
  display: block;
}

.mobile_view {
  display: none;
}

.product_image img {
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
}

.sortable_button {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.875rem !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 16px !important;
  color: #333 !important;
}

.sort_indicator {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.sort_indicator.asc {
  color: #4caf50;
}

.sort_indicator.desc {
  color: #f44336;
}

.product_card {
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product_card:hover {
  background-color: #f5f5f5;
}

.product_image {
  flex: 0 0 80px;
  margin-right: 1rem;
}

.product_details {
  flex: 1;
}

.product_field {
  margin-bottom: 0.5rem;
}

.field_label {
  font-weight: bold;
  margin-right: 0.5rem;
  color: #757575;
}

@media (max-width: 768px) {
  .desktop_view {
    display: none;
  }

  .mobile_view {
    display: block;
    margin: 0.5rem;
  }

  .search_input {
    margin-left: 0.5rem !important;
  }

  .product_image img {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
  }

  .sort_buttons {
    display: flex;
    justify-content: space-around;
    overflow-x: auto;
    gap: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .sort_buttons::-webkit-scrollbar {
    display: none;
  }
}
