.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(243, 244, 246);
}

.login-card {
  width: 820px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  display: flex;
  position: relative;
}

.login-diagonal {
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: black;
  clip-path: polygon(100% 0, 0 0, 100% 120%);
}

.login-content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
}

.login-left-side {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-right-side {
  width: 50%;
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 8%;
  text-align: right;
}

.login-logo {
  width: 100%;
  height: auto;
}

.login-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
}

.login-title-underline {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: black;
}

.login-input-container {
  position: relative;
  margin-bottom: 20px;
}

.login-input {
  width: 100%;
  padding: 10px 30px 10px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e5e7eb;
  font-size: 16px;
  outline: none;
  background-color: transparent;
}

.login-input.error {
  border-bottom-color: #ff4d4f;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-button:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.login-signup-text {
  text-align: center;
  font-size: 14px;
  color: #6b7280;
}

.login-signup-link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.login-welcome-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.login-welcome-text {
  font-size: 14px;
}

@media (max-width: 767px) {
  .login-card {
    width: 100%;
    height: auto;
    margin: 16px;
  }

  .login-content {
    flex-direction: column;
  }

  .login-left-side {
    width: 100%;
    padding: 20px;
  }

  .login-right-side {
    display: none;
  }

  .login-diagonal {
    display: none;
  }

  .login-input-container {
    margin-bottom: 30px;
  }

  .error-message {
    bottom: -25px;
  }
}

/* Remove default autofill styles for WebKit browsers (Chrome, Safari) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* Remove default autofill styles for Firefox */
input:autofill {
  background-color: transparent !important;
  color: inherit !important;
}

/* Ensure autofilled inputs use your custom styles */
.login-input:-webkit-autofill,
.login-input:autofill {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e5e7eb;
  font-size: 16px;
  outline: none;
  background-color: transparent !important;
}

/* Maintain error state for autofilled inputs */
.login-input.error:-webkit-autofill,
.login-input.error:autofill {
  border-bottom-color: #ff4d4f;
}
