.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.tableHead {
  background-color: #f5f5f5;
}

.tableHeadCell {
  color: inherit;
  max-width: 100px;
  font-weight: bold;
  padding: 16px;
}

.tableCellPrice {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableCell {
  color: inherit;
  padding: 16px;
}

.card {
  width: 100%;
}

.cardTitle {
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  background-color: #f5f5f5;
  color: #333;
}

.borderNone {
  border-bottom: none !important;
}

.tableCellDescription {
  min-width: 100px;
  max-width: 200px;
}

.vehicleConfigOptionLabel {
  font-weight: bold;
  margin-right: 4px;
}

.priceInput {
  width: 100%;
  border: none;
  background: transparent;
  color: inherit;
}

.totalRow {
  font-weight: bold;
}

.configCell {
  padding: 0 !important;
}

.configBox {
  padding: 0 16px 8px 16px;
}

.configTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

.configOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.configOption {
  display: inline-flex;
  align-items: center;
}

.configLabel {
  font-weight: 500;
  margin-right: 4px;
}

.button {
  padding: 12px 24px !important;
  font-weight: 600;
  text-transform: none;
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button:hover {
  background-color: #3f51b5;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.3);
}

.linkButton {
  padding: 12px 24px;
  font-weight: 600;
  text-transform: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.linkButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
