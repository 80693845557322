.editContainer {
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  margin-bottom: 16px;
  font-weight: 500;
}

.imageInput {
  margin-top: 20px;
}

.categoryTitle {
  margin-top: 24px !important;
  margin-bottom: 8px !important;
}

.configInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.configInput:focus {
  outline: none;
  border-color: #1976d2;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.inputContainer {
  position: relative;
}

.inputContainer > div {
  margin-top: 0;
}

.tempInput {
  width: 100%;
}

.removeIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #f44336;
  font-size: 18px;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  line-height: 1;
}

.removeIcon:hover {
  color: #d32f2f;
}

.inputContainer {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input:focus {
  outline: none;
  border-color: #1976d2;
}

.input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.textarea {
  resize: vertical;
  min-height: 100px;
}

.imageInput {
  margin-top: 20px;
}

.categoryTitle {
  margin-top: 24px !important;
  margin-bottom: 8px !important;
}
