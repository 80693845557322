.showContainer {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.MuiCard-root {
  background-color: inherit !important;
}

.cardContainer {
  margin-bottom: 24px;
}

.cardTitle {
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  background-color: #f5f5f5;
  color: #333;
}

.cardContent {
  padding: 24px;
}

.tableHeadCell {
  font-weight: bold;
}

.tableBodyCell {
  color: inherit;
}

.chip {
  font-weight: bold;
  color: #fff;
}

.detailItem {
  margin-bottom: 16px;
}

.detailItemLabel {
  font-weight: 600;
  margin-bottom: 8px;
}

.actionButton {
  margin-top: 16px;
  justify-content: flex-end;
}

.configOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 4px;
}

.configOption {
  display: inline-flex;
  align-items: center;
}

.configLabel {
  font-weight: bold;
  margin-right: 4px;
}

.separator {
  margin: 0 4px;
  color: #888;
}

.trackingTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333;
}

.trackingGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.trackingField {
  display: flex;
  flex-direction: column;
}

.trackingLabel {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
}

.trackingInput,
.trackingTextarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.trackingTextarea {
  resize: vertical;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  overflow: hidden;
  min-height: 100px;
  box-sizing: border-box;
}

.updateButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.updateButton:hover {
  background-color: #1976d2;
}

.updateButton:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .trackingGrid {
    grid-template-columns: 1fr;
  }
}
