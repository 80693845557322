.root {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.cardTitle {
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
  background-color: #f5f5f5;
  color: #333;
}

.cardContent {
  padding: 16px 0;
}

.tableCell {
  font-weight: bold;
  color: inherit;
}

.buttonContainer {
  margin: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.configurationsLabel {
  margin-right: 8px !important;
}

.enquiryConfigurations {
  display: flex;
  align-items: center;
}

.button {
  padding: 12px 24px !important;
  font-weight: 600;
  text-transform: none;
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button:hover {
  background-color: #3f51b5;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.3);
}

.linkButton {
  padding: 12px 24px;
  font-weight: 600;
  text-transform: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.linkButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.configOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.configOption {
  display: inline-flex;
  align-items: center;
}

.configLabel {
  font-weight: 500;
  margin-right: 4px;
}

.separator {
  color: #999;
  margin: 0 8px;
}
