.cardContainer {
  margin-bottom: 20px;
}

.cardTitle {
  padding: 16px;
  background-color: #f5f5f5;
}

.cardContent {
  padding: 16px;
}

.trackingTitle {
  margin-bottom: 16px;
}

.trackingGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.trackingField {
  display: flex;
  flex-direction: column;
}

.trackingLabel {
  margin-bottom: 8px;
  font-weight: bold;
}

.trackingInput,
.trackingTextarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.trackingTextarea {
  min-height: 100px;
  resize: vertical;
}

.updateButton,
.uploadButton {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.updateButton:disabled,
.uploadButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.imageUploadSection {
  margin-top: 24px;
}

.imageUploadTitle {
  margin-bottom: 16px;
}

.fileInput {
  margin-bottom: 16px;
}
