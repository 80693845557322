.buttonContainer {
  display: flex;
  gap: 1rem;
}

.button {
  padding: 12px 24px !important;
  font-weight: 600;
  border-radius: 8px;
  margin: 8px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.button:hover {
  opacity: 0.9;
}

.cancelButton {
  color: #f44336;
  border-color: #f44336;
}

.confirmButton {
  background-color: #4caf50;
  color: white;
}

.confirmButton:hover {
  background-color: #45a049;
}

.modalContainer {
  padding: 24px;
  min-width: 400px;
}

.dialogTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
}

.dialogContent {
  color: #666;
  margin-bottom: 24px;
}

.dialogActions {
  padding: 16px 0 0;
}

.linkButton {
  text-decoration: none;
  color: #3f51b5;
  padding: 8px 16px;
  border: 1px solid #3f51b5;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.linkButton:hover {
  background-color: #3f51b5;
  color: white;
}

.fileInput {
  margin-top: 16px;
}

.fileInputLabel > span {
  padding: 8px 0;
}

.errorText {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 4px;
}

.textField {
  margin-bottom: 16px;
}

.uploadButton {
  background-color: #3f51b5;
  color: white;
}

.uploadButton:hover {
  background-color: #303f9f;
}
