.quotation_list {
  margin: 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.desktop_view {
  display: block;
}

.desktop_view table {
  width: 100%;
  border-collapse: collapse;
}

.desktop_view th,
.desktop_view td {
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.desktop_view tr:last-child td {
  border-bottom: none;
}

.desktop_view tr:hover {
  background-color: #f9f9f9;
}

.mobile_view {
  display: none;
  overflow-x: hidden;
}

.quotation_card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quotation_field {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.quotation_field:last-child {
  margin-bottom: 0;
}

/* Mobile styles */
@media (max-width: 768px) {
  .desktop_view {
    display: none;
  }

  .mobile_view {
    display: block;
  }

  .quotation_list {
    margin: 0;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.pagination button {
  margin-left: 0.5rem;
}

.mobile_view .sort_buttons {
  display: flex;
  overflow-x: auto;
  justify-content: space-around;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0.5rem 0;
  margin: 0 -1rem;
  padding: 0.5rem 1rem;
}

.mobile_view .sort_buttons button.sortable_button {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sort_indicator.asc {
  color: #4caf50;
}

.sort_indicator.desc {
  color: #f44336;
}
