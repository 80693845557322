.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.closeButton {
  position: absolute !important;
  right: 8px;
  top: 8px;
}

.modalTitle {
  margin-bottom: 16px !important;
  font-weight: bold !important;
}

.productInfo {
  display: flex;
  align-items: center;
  margin-bottom: 16px !important;
}

.productImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 16px;
  border-radius: 4px;
}

.configurationSection {
  margin-bottom: 16px !important;
}

.configurationTitle {
  margin-bottom: 8px !important;
  font-weight: 600 !important;
}

.optionButtonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.optionButton {
  flex: 1 0 calc(33.333% - 8px);
  min-width: 80px;
}

.errorText {
  margin-top: 8px;
  margin-bottom: 16px !important;
  color: #f44336;
}

.addToCartButton {
  margin-top: 16px;
  padding: 12px;
  font-weight: bold;
  background-color: #000;
  color: #fff;
}

.addToCartButton:not(:disabled):hover {
  background-color: #333;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .modalContent {
    width: 95%;
    padding: 16px;
  }

  .productImage {
    width: 80px;
    height: 80px;
  }

  .optionButton {
    flex: 1 0 calc(50% - 8px);
  }
}
