.configCell {
  padding: 0 !important;
}

.configBox {
  padding: 0 16px 8px 16px;
}

.configTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

.configOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.configOption {
  display: inline-flex;
  align-items: center;
}

.configLabel {
  font-weight: 500;
  margin-right: 4px;
}

.bottomContainer {
  display: flex;
  align-items: center;
  margin: 16px 0;
  justify-content: space-between;
}

.bottomLeftContainer {
  padding-left: 16px;
}

.button {
  padding: 12px 24px !important;
  font-weight: 600 !important;
}

.modalContainer {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  max-width: 500px;
}

.dialogTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.dialogContent {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: none;
}

.cancelButton {
  color: #888 !important;
  background-color: transparent !important;
  border: 1px solid #ccc !important;
  margin-right: 10px !important;
}

.confirmButton {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.confirmButton:hover {
  background-color: #45a049 !important;
}

.borderNone {
  border-bottom: none !important;
}
