.enquiry_list {
  margin: 1rem;
  border-radius: 4px;
  overflow: hidden;
}

/* Desktop styles */
.desktop_view {
  display: block;
}

.desktop_view table {
  width: 100%;
  border-collapse: collapse;
}

.desktop_view th,
.desktop_view td {
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.desktop_view tr:last-child td {
  border-bottom: none;
}

.desktop_view tr:hover {
  background-color: #f9f9f9;
}

/* Mobile styles */
.mobile_view {
  display: none;
  overflow-x: hidden;
}

.enquiry_card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enquiry_field {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.enquiry_field:last-child {
  margin-bottom: 0;
}

.field_label {
  font-weight: bold;
  margin-right: 0.5rem;
}

.field_value {
  flex: 1;
  text-align: right;
  word-break: break-word;
}

/* Sortable Button Styles */
.mobile_view .sort_buttons {
  display: flex;
  overflow-x: auto;
  justify-content: space-around;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0.5rem 1rem;
  margin: 0 -1rem;
}

.mobile_view .sort_buttons::-webkit-scrollbar {
  display: none;
}

.mobile_view .sort_buttons button.sortable_button {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.mobile_view .sort_buttons button.sortable_button:last-child {
  margin-right: 0;
}

.mobile_view .sort_buttons button.sortable_button:hover,
.mobile_view .sort_buttons button.sortable_button:focus {
  background-color: #e0e0e0;
}

.mobile_view .sort_buttons button.sortable_button .sort_indicator {
  margin-left: 0.25rem;
  display: inline-flex;
  align-items: center;
}

.sort_indicator.asc {
  color: #4caf50;
}

.sort_indicator.desc {
  color: #f44336;
}

.mobile_view .sort_buttons button.sortable_button span {
  white-space: nowrap;
}

.mobile_view .sort_buttons button.sortable_button .sort_indicator svg {
  font-size: 1rem;
}

.desktop_view .sortable_button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #2196f3;
}

.desktop_view .sortable_button:hover {
  text-decoration: underline;
}

.desktop_view .sortable_button .sort_indicator {
  margin-left: 0.25rem;
}

@media (max-width: 768px) {
  .desktop_view {
    display: none;
  }

  .mobile_view {
    display: block;
  }

  .enquiry_list {
    margin: 0;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.pagination button {
  margin-left: 0.5rem;
}

.RaTopToolbar-root {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.RaCreateButton-root button {
  width: 56px !important;
  height: 56px !important;
  border-radius: 50% !important;
  background-color: #2196f3 !important;
  color: white !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2) !important;
}

.RaCreateButton-root button:hover {
  background-color: #1976d2 !important;
}

.RaCreateButton-root .MuiButton-startIcon {
  margin: 0 !important;
}

.RaCreateButton-root .MuiButton-label > span:last-child {
  display: none !important;
}

@media (max-width: 768px) {
  .RaTopToolbar-root {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .RaList-actions > .MuiToolbar-regular {
    max-width: 40% !important;
  }

  .MuiIconButton-root {
    margin-bottom: 0.25rem !important;
  }

  .RaTopToolbar-root > * {
    margin-bottom: 0.5rem;
  }

  .search_input {
    margin-left: 0.5rem !important;
  }

  .enquiry_list .RaListToolbar-root,
  .enquiry_list [class*='RaListToolbar-root'] {
    flex-direction: row !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
  }
}

.RaCreateButton-root {
  z-index: 1000 !important;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/* Additional styles for mobile view */
.mobile_list {
  padding: 0 1rem;
}

/* Styles for the search input */
.search_input {
  margin-bottom: 1rem;
}

/* Ensure proper spacing in the mobile view */
.mobile_view .enquiry_card:first-child {
  margin-top: 0;
}

.mobile_view .enquiry_card:last-child {
  margin-bottom: 0;
}

/* Enhance readability of the enquiry card on hover */
.enquiry_card:hover {
  background-color: #f5f5f5;
}

/* Style adjustments for better mobile experience */
@media (max-width: 480px) {
  .enquiry_card {
    margin: 0.5rem 0;
  }

  .mobile_view .sort_buttons {
    padding: 0.5rem 0;
  }
}

/* Ensure proper alignment of the create button on smaller screens */
@media (max-width: 600px) {
  .RaCreateButton-root {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
}

/* Additional styles to ensure consistency across different browsers */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
